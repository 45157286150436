const Projects = [
  {
    id: "1",
    img: "https://i.ibb.co/c8w4XvD/project-image-3.png",
    header: "Watchly",
    text: "An app that could potentially allow you watch movies with friend. Twitter spaces but for movies",
  },
  {
    id: "2",
    img: "https://i.ibb.co/pKQfHxg/project-image-4.png",
    header: "Watchly",
    text: "An app that could potentially allow you watch movies with friend. Twitter spaces but for movies",
  },
  // {
  //   id: "3",
  //   img: "https://i.ibb.co/c8w4XvD/project-image-3.png",
  //   header: "Watchly",
  //   text: "An app that could potentially allow you watch movies with friend. Twitter spaces but for movies",
  // },
  // {
  //   id: "4",
  //   img: "https://i.ibb.co/pKQfHxg/project-image-4.png",
  //   header: "Watchly",
  //   text: "An app that could potentially allow you watch movies with friend. Twitter spaces but for movies",
  // },
];

export default Projects;
